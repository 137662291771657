export default class {
	constructor() {
		this.counterWrap = '.js-counter-wrap';
		this.number = '.js-counter-num';
		this.init();
	}

	init() {
		const counterWrap = document.querySelector(this.counterWrap);
		const counters = document.querySelectorAll(this.number);
		let flag = false;

		if (counterWrap) {
			window.addEventListener('scroll', () => {
				if (counterWrap.classList.contains('is-active')) {
					if (!flag) {
						flag = true;
						counters.forEach((counter) => {
							setCounter(counter);
						});
					}
				}
			});

			function setCounter(counter) {
				const targetNum01 = +counter.getAttribute("data-target");
				let targetNum = targetNum01.toFixed(0);
				let displayNum = 0; // カウンターが最初に表示される時は 0 からスタート
				const SPEED = 250;
				const increment = targetNum / SPEED;

				const updateCounter = () => {
					if (displayNum < targetNum) {
						displayNum += increment;
						counter.innerText = `${displayNum.toFixed(0)}`;
						setTimeout(updateCounter, 1);
					} else {
						counter.innerText = targetNum;
					}
				};
				updateCounter();
			}
		}
	}
}
