export default class {
	constructor() {
		this.text = '.js-text-split';
		this.textAnm();
	}

	textAnm() {
		const txtWrap = document.querySelectorAll(this.text);
		if (txtWrap) {
			txtWrap.forEach(element => {
				this.processNode(element);
				element.style.height = element.clientHeight + 'px';
			});
		}
	}

	processNode(node) {
		const childNodes = [...node.childNodes];
		let decoTxtWrap = "";
		childNodes.forEach(childNode => {
			if (childNode.nodeType === Node.TEXT_NODE) {
				let text = childNode.textContent.replace(/\r?\n/g, '');
				decoTxtWrap = decoTxtWrap + text.split('').reduce((acc, v) => {
					return acc + `<span>${v}</span>`;
				}, "");
			} else if (childNode.tagName && childNode.tagName.toLowerCase() === 'span') {
				// <span>タグの場合も同様に処理
				const spanContent = this.getSpanContent(childNode);
				const spanAttributes = this.getSpanAttributes(childNode);
				decoTxtWrap = decoTxtWrap + `<span${spanAttributes}>${spanContent}</span>`;
			} else {
				decoTxtWrap = decoTxtWrap + childNode.outerHTML;
			}
		});
		node.innerHTML = decoTxtWrap;
	}

	getSpanContent(spanNode) {
		const spanChildNodes = [...spanNode.childNodes];
		let spanContent = "";
		spanChildNodes.forEach(childNode => {
			if (childNode.nodeType === Node.TEXT_NODE) {
				let text = childNode.textContent.replace(/\r?\n/g, '');
				spanContent = spanContent + text.split('').reduce((acc, v) => {
					return acc + `<span>${v}</span>`;
				}, "");
			} else if (childNode.tagName) {
				const tagName = childNode.tagName.toLowerCase();
				const outerHTML = childNode.outerHTML;
				if (tagName === 'br') {
					spanContent += '<br>';
				} else {
					spanContent += outerHTML;
				}
			}
		});
		return spanContent;
	}

	getSpanAttributes(spanNode) {
		const attributes = spanNode.attributes;
		let attributeString = "";
		for (let i = 0; i < attributes.length; i++) {
			attributeString += ` ${attributes[i].name}="${attributes[i].value}"`;
		}
		return attributeString;
	}
}
