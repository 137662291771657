import "../scss/style.scss";

/**
 * @babel/polyfill の利用
 * ※ PromiseやArray.includes()など、構文ではない機能的な仕様を利用するために必要
 */
import "@babel/polyfill";

import lazysizes from "lazysizes"
import unveil from "lazysizes/plugins/unveilhooks/ls.unveilhooks.min.js"

// if ('NodeList' in window && !NodeList.prototype.forEach) {
//     NodeList.prototype.forEach = function (callback, thisArg) {
//         thisArg = thisArg || window;
//         for (var i = 0; i < this.length; i++) {
//             callback.call(thisArg, this[i], i, this);
//         }
//     };
// }

import Accordion from "./lib/Accordion";
import Header from "./lib/Header";
import Drawer from "./lib/Drawer";
import Headroom from "./lib/Headroom";
import ResponsiveImages from "./lib/ResponsiveImages";
import switchViewport from "./lib/switchViewport";
import Tab from "./lib/Tab";
import BusinessNav from "./lib/BusinessNav";
import Counter from "./lib/Counter";
import Inview from "./lib/Inview";
import TextAnimation from "./lib/TextAnimation";
import Slider from "./lib/Slider";
import products from "./lib/products";
import Scroll from "./lib/Scroll";
import ScrollHint from "./lib/ScrollHint";

new Accordion();
new Header();
new Drawer();
new Headroom();
new ResponsiveImages();
new switchViewport();
new Tab();
new BusinessNav();
new Counter();
new Inview();
new TextAnimation();
new Slider();
new products();
new Scroll();
new ScrollHint();
