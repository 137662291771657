import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class {
    constructor() {
        this.init();
        this.history();
    }

	init() {
		const areas = document.querySelectorAll('.js-index__area');
		const options = {
			root: null,
			rootMargin: '-50% 0px',
			threshold: 0
		};
		const observer = new IntersectionObserver(doWhenIntersect, options);
		areas.forEach(area => {
			observer.observe(area);
		});
		/**
		* 交差したときに呼びだす関数
		* @param entries
		*/
		function doWhenIntersect(entries) {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					activateIndex(entry.target);
				}
			});
		}
		/**
		* 目次の色を変える関数
		* @param element
		*/
		function activateIndex(element) {
			const currentActiveIndex = document.querySelector('.c-layout01__anchor-list .is-active');
				if(currentActiveIndex !== null) {
					currentActiveIndex.classList.remove('is-active');
				}
			const newActiveIndex = document.querySelector(`a[href='#${element.id}']`);
			newActiveIndex.classList.add('is-active');
		}
	}
	history() {
		const logo = document.querySelector('.js-logo');
		if (logo) {
			const wrapper = document.querySelector('.js-logoWrapper');
			ScrollTrigger.create({
				//markers: true,
				trigger: logo, //アニメーションが始まるトリガーとなる要素
				endTrigger: wrapper,
				start: 'center center',
				end: 'end 75%',
				pin: true,
			});
		}
	}
}