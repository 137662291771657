export default class {
	constructor() {
		this.business = '.js-business-nav';
		this.item = '.js-business-nav__item';
		this.init();
	}

	init() {
		const nav = document.querySelector(this.business);
		if (nav) {
			const items = Array.from(nav.querySelectorAll(this.item));
			items.forEach(item => {
        item.addEventListener('mouseover', this.handleMouseOver.bind(this, items, item));
      });
		}
	}
	handleMouseOver(items, currentItem) {
		items.forEach(element => {
			element.classList.remove('is-active');
		});
		currentItem.classList.add('is-active');
	}
}