import ScrollHint from 'scroll-hint'

export default class {
    constructor() {
        this.init();
    }
    init() {
			new ScrollHint('.js-scrollable', {
				//applyToParents: true,
				i18n: {
					scrollable: 'スクロールできます'
				}
			});
    }
}