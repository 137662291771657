export default class {
	constructor() {
			this.item = ".js-products__item";
			this.mainItem = document.getElementById('js-products__main');
			this.init();
	}
	init() {
		const item = document.querySelectorAll(this.item);
		const main = this.mainItem;
		//console.log()
		if (item) {
			for (let i = 0; i < item.length; i++) {
				item[i].addEventListener('click',clickAction)
			}
			function clickAction() {
				for (let i = 0; i < item.length; i++) {
					item[i].classList.remove('-current');
				}
				this.classList.add('-current');
				let clickSrc = this.querySelector('img').src;
				main.src = clickSrc;
			}
		}
	}
}