import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
export default class {
	constructor() {

		this.init();
	}

	init() {
		const swiper = new Swiper('.js-pickup', {
			speed: 400,
			spaceBetween: 0,
			slidesPerView: 1,
			//loop: true,
			navigation: {
				nextEl: '.js-pickup__pager-next',
				prevEl: '.js-pickup__pager-prev',
			},
			pagination: {
				el: '.js-pickup__pagination',
				type: 'bullets',
				clickable: true,
			},
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
			},
			breakpoints: {
				1008: {
					slidesPerView: 3,
					spaceBetween: 32
				},
			}
		});
	}
}