import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class {
    constructor() {
        this.init();
    }

	init() {
		const fadeItems = gsap.utils.toArray('.js-inview');
		fadeItems.forEach((fadeItem) => {
			ScrollTrigger.create({
				//markers: true,
				trigger: fadeItem, //アニメーションが始まるトリガーとなる要素
				start: 'top 75%',
				end: 'bottom 75%',
				toggleClass: {
					targets: fadeItem,
					className: "is-active"
				},
				once: true
			});
		});
	}
}